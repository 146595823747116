<template>
    <v-dialog v-model="dialog" :persistent="persistent" max-width="600px" transition="dialog-transition">
        <template v-slot:activator="{ on, attrs }">
            <div class="d-inline" @click.stop="open">
                <slot></slot>
            </div>
        </template>
        <v-card class="rounded-lg pa-5">
            <div class="header">
                <v-avatar v-if="icon" size="48" :color="iconBackground">
                    <v-icon size="20" :color="iconColor">{{ icon }}</v-icon>
                </v-avatar>
                <h1 class="settings-header">{{ question }}</h1>
            </div>
            <div>
                <div class="mt-5">
                    <SettingsLabel v-if="questionDetails">
                        {{ questionDetails }}
                    </SettingsLabel>
                    <slot name="details"></slot>
                </div>
            </div>
            <div v-if="advanced" class="mt-5">
                <v-text-field
                    hide-details="auto"
                    outlined
                    dense
                    v-model="confirmText"
                    placeholder='Schreibe "Löschen", um die Löschung zu bestätigen.'
                ></v-text-field>
            </div>
            <div class="d-flex mt-5">
                <v-btn outlined class="mr-2 flex-grow-1 btnGreyOutlined" @click="bad">{{ negativeAnswer }}</v-btn>
                <v-btn
                    :loading="loading"
                    :color="buttonColor"
                    class="flex-grow-1"
                    :disabled="advanced && confirmText.toLowerCase() !== 'löschen'"
                    @click="good"
                    >{{ positiveAnswer }}</v-btn
                >
            </div>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'YesNoDialog',
    data() {
        return {
            dialog: false,
            confirmText: '',
        };
    },
    props: {
        icon: {
            type: String,
        },
        iconColor: {
            type: String,
            default: '#344054',
        },
        iconBackground: {
            type: String,
            default: '#EAECF0',
        },
        question: {
            type: String,
            required: true,
        },
        questionDetails: {
            type: String,
        },
        positiveAnswer: {
            type: String,
            default: 'Bestätigen',
        },
        buttonColor: {
            type: String,
            default: 'primary',
        },
        negativeAnswer: {
            type: String,
            default: 'Abbrechen',
        },
        persistent: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        advanced: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        open() {
            this.dialog = true;
        },
        good() {
            if (!this.advanced || (this.advanced && this.confirmText.toLowerCase() === 'löschen')) {
                this.$emit('good');
                this.confirmText = '';
                this.dialog = false;
            }
        },
        bad() {
            this.$emit('bad');
            this.dialog = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.header {
    display: flex;
    align-items: center;
    gap: 16px;
}
</style>
