import Vue from 'vue';

// components
import AudioPlayer from '@/app/shared/components/AudioPlayer.vue';
import AvatarList from '@/app/shared/components/AvatarList.vue';
import Badge from '@/app/shared/components/Badge.vue';
import BigButton from '@/app/shared/components/BigButton.vue';
import Divider from '@/app/shared/components/Divider.vue';
import EmptyStateArt from '@/app/shared/components/EmptyStateArt.vue';
import Heading from '@/app/shared/components/Heading.vue';
import IconButton from '@/app/shared/components/IconButton.vue';
import Instructions from '@/app/shared/components/Instructions.vue';
import Loader from '@/app/shared/components/Loader.vue';
import RadioTextGroup from '@/app/shared/components/RadioTextGroup.vue';
import SettingsBase from '@/app/shared/components/SettingsBase.vue';
import SettingsLabel from '@/app/shared/components/SettingsLabel.vue';
import TextInputPrependInnerLabel from '@/app/shared/components/TextInputPrependInnerLabel.vue';
import ThumbnailCard from '@/app/shared/components/ThumbnailCard.vue';
import VideoPlayer from '@/app/shared/components/VideoPlayer.vue';
import YesNoDialog from '@/app/shared/components/YesNoDialog.vue';
import IntervalTime from './app/shared/components/IntervalTime.vue';
import SettingsItem from './components/blueprints/settings/SettingsItem.vue';
import NewFeatureAlert from './app/shared/components/NewFeatureAlert.vue';

export const registerSharedComponents = () => {
    Vue.component('AudioPlayer', AudioPlayer);
    Vue.component('AvatarList', AvatarList);
    Vue.component('Badge', Badge);
    Vue.component('BigButton', BigButton);
    Vue.component('Divider', Divider);
    Vue.component('EmptyStateArt', EmptyStateArt);
    Vue.component('Heading', Heading);
    Vue.component('IconButton', IconButton);
    Vue.component('Instructions', Instructions);
    Vue.component('Loader', Loader);
    Vue.component('RadioTextGroup', RadioTextGroup);
    Vue.component('SettingsBase', SettingsBase);
    Vue.component('SettingsItem', SettingsItem);
    Vue.component('SettingsLabel', SettingsLabel);
    Vue.component('TextInputPrependInnerLabel', TextInputPrependInnerLabel);
    Vue.component('ThumbnailCard', ThumbnailCard);
    Vue.component('VideoPlayer', VideoPlayer);
    Vue.component('YesNoDialog', YesNoDialog);
    Vue.component('IntervalTime', IntervalTime);
    Vue.component('NewFeatureAlert', NewFeatureAlert);
};
