<template>
    <div class="empty-state-container" :class="border ? 'border' : ''" :style="cssStyle">
        <v-sheet class="rounded-lg" style="background-color: inherit" elevation="0" :max-width="maxWidth">
            <div class="text-center pa-5">
                <div>
                    <div v-if="icon" style="margin-bottom: 12px">
                        <v-icon :class="noBorderedIcon ? 'noBorderedIconClass' : 'borderedIcon'">{{ icon }}</v-icon>
                    </div>

                    <div v-if="title" class="infoHeader">
                        {{ title }}
                    </div>
                    <span v-if="description" class="infoDescription">
                        {{ description }} <br />
                        <a v-if="linkText" @click="$emit('link')">{{ linkText }}</a>
                    </span>
                    <span v-else-if="linkText" class="infoDescription">
                        <a @click="$emit('link')">{{ linkText }}</a>
                    </span>
                </div>
                <div class="mt-3">
                    <slot />

                    <div
                        v-if="$slots.buttons"
                        style="display: flex; justify-content: center; flex-wrap: wrap; gap: 16px"
                    >
                        <slot name="buttons" />
                    </div>
                </div>
            </div>
        </v-sheet>
    </div>
</template>

<script>
export default {
    props: {
        icon: {
            type: String,
            required: false,
        },
        noBorderedIcon: {
            type: Boolean,
            required: false,
            default: false,
        },
        border: {
            type: Boolean,
            default: false,
        },
        maxWidth: {
            type: String,
            required: false,
            default: '400px',
        },
        backgroundColor: {
            type: String,
            required: false,
            default: 'unset',
        },
        title: {
            type: String,
            required: true,
        },
        description: {
            type: String,
            required: false,
        },
        linkText: {
            type: String,
            required: false,
        },
        borderColor: {
            type: String,
            default: '#f5f5f5',
        },
        titleBalancedWrap: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        cssStyle() {
            let borderColor = this.borderColor;
            let borderWidth = '1px';

            if (this.borderColor === 'leadtable') {
                borderColor = '#BA07CC';
                borderWidth = '2px';
            }

            return {
                'background-color': this.backgroundColor,
                'border-color': borderColor,
                'border-width': borderWidth,
            };
        },
    },
};
</script>

<style lang="scss">
.empty-state-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    background-color: transparent;

    &.border {
        border-radius: 12px;
        border: 1px solid;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    }
}

.borderedIcon {
    border-radius: 10px;
    border: 1px solid var(--gray-200, #eaecf0);
    background: var(--base-white, #fff);
    padding: 10px;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    size: 30px;
}

.noBorderedIconClass {
    padding: 10px;
    size: 30px;
}

.infoHeader {
    color: var(--gray-900, #101828);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    padding-bottom: 8px;
}

.infoDescription {
    color: var(--gray-600, #475467);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 142.857% */
}
</style>
