<template>
    <div class="newFeatureAlert__body" v-if="show">
        <div class="newFeatureAlert__icon">
            <div class="newFeatureAlert__icon__wrapper">
                <div class="newFeatureAlert__icon__inner">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <g clip-path="url(#clip0_5969_1939)">
                            <path
                                d="M10 13.3333V9.99996M10 6.66663H10.0084M18.3334 9.99996C18.3334 14.6023 14.6024 18.3333 10 18.3333C5.39765 18.3333 1.66669 14.6023 1.66669 9.99996C1.66669 5.39759 5.39765 1.66663 10 1.66663C14.6024 1.66663 18.3334 5.39759 18.3334 9.99996Z"
                                stroke-width="1.66667"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_5969_1939">
                                <rect width="20" height="20" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                </div>
            </div>
        </div>

        <div class="newFeatureAlert__close" @click="close">
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                <path
                    d="M11 1L1 11M1 1L11 11"
                    stroke="#667085"
                    stroke-width="1.66667"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
        </div>

        <div class="newFeatureAlert__content">
            <div>
                <div class="newFeatureAlert__title">
                    <slot name="title"></slot>
                </div>
                <slot></slot>
            </div>
            <div class="d-flex gap-1">
                <a class="newFeatureAlert__dismiss" @click="dismiss" color="#475467" text>Nicht mehr anzeigen</a>
                <slot name="actions"></slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        id: {
            type: String,
            required: true,
        },
    },

    created() {
        if (localStorage.getItem(`newFeatureAlertDismissed_${this.id}`)) {
            this.show = false;
        }
    },

    data() {
        return {
            show: true,
        };
    },
    methods: {
        close() {
            this.show = false;
        },
        dismiss() {
            localStorage.setItem(`newFeatureAlertDismissed_${this.id}`, 'true');

            this.show = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.newFeatureAlert {
    &__body {
        border-radius: 12px;
        border: 1px solid var(--Gray-200, #eaecf0);
        background: var(--Base-White, #fff);

        /* Shadow/lg */
        box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
        display: flex;
        padding: 16px;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;
        position: relative;
        min-height: 100px;
    }

    &__content {
        padding-top: 40px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        color: var(--Gray-600, #475467);

        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
    }

    &__title {
        color: var(--Gray-900, #101828);
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px; /* 142.857% */
    }

    &__dismiss {
        color: var(--Gray-600, #475467);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */

        &:hover {
            text-decoration: underline;
        }

        &:active {
            color: var(--Gray-700, #2f3e4e);
        }
    }

    &__icon {
        position: absolute;
        top: 8px;
        left: 8px;
        display: flex;
        justify-content: center;
        align-items: center;

        &__wrapper {
            width: 36px;
            height: 36px;
            border-radius: 24px;
            border: 2px solid color-mix(in srgb, var(--v-primary-base, #7f56d9), transparent 80%);
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &__inner {
            width: 28px;
            height: 28px;
            flex-shrink: 0;
            border-radius: 24px;
            border: 2px solid color-mix(in srgb, var(--v-primary-base, #7f56d9), transparent 50%);
            background-color: transparent;
            display: flex;
            justify-content: center;
            align-items: center;

            svg {
                stroke: var(--v-primary-base, #7f56d9);
            }
        }
    }
    &__close {
        position: absolute;
        top: 16px;
        right: 16px;
        cursor: pointer;

        svg {
            transition: transform 0.2s;
        }

        &:hover {
            svg {
                transform: rotate(90deg);
            }
        }
    }
}
</style>
