<template>
    <v-snackbar
        right
        bottom
        dense
        :timeout="automationAbortTimeout"
        v-model="automationAbortActive"
        elevation="0"
        class="snackBarBody"
        color="transparent"
    >
        <div class="alertBody">
            <div class="alertMain d-flex">
                <div class="iconOuter" :style="{ 'border-color': '#e6f4ff' }">
                    <div class="iconInner" :style="{ 'border-color': '#b5d9f5' }">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path
                                d="M9.99996 6.66663V9.99996M9.99996 13.3333H10.0083M18.3333 9.99996C18.3333 14.6023 14.6023 18.3333 9.99996 18.3333C5.39759 18.3333 1.66663 14.6023 1.66663 9.99996C1.66663 5.39759 5.39759 1.66663 9.99996 1.66663C14.6023 1.66663 18.3333 5.39759 18.3333 9.99996Z"
                                stroke="#0A6ED1"
                                stroke-width="1.66667"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                    </div>
                </div>

                <div>
                    <div class="alertText">Automatisierung ausgelöst</div>
                    <div class="alertDescription">
                        {{ automationAbortData.count || '-' }}
                        {{ automationAbortData.count > 1 ? 'Benachrichtigungen werden' : 'Benachrichtigung wird' }}
                        verschickt
                    </div>
                </div>

                <v-btn x-small icon @click="closeSnackbar" class="closeButton">
                    <v-icon size="20" color="#101828">mdi-close</v-icon>
                </v-btn>
            </div>

            <div class="d-flex justify-center pa-2">
                <v-btn color="primary" text @click="abortAutomation">Abbrechen</v-btn>
            </div>

            <v-progress-linear v-model="value" active />
        </div>
    </v-snackbar>
</template>

<script>
import { mapState } from 'vuex';

export default {
    data() {
        return {
            value: 100,
        };
    },
    methods: {
        closeSnackbar() {
            this.$store.commit('closeAutomationAbort');
            this.value = 100;
            clearInterval(this.interval);
        },
        async abortAutomation() {
            if (this.automationAbortData.bulkIDs) {
                await this.$api.post('emailautomation/automations/abortBulk', {
                    bulkIDs: this.automationAbortData.bulkIDs,
                });
            } else if (this.automationAbortData.emailIDs) {
                for (const emailID of this.automationAbortData.emailIDs) {
                    await this.$api.put(`emailautomation/automations/cancel`, {
                        emailID,
                    });
                }
            }
            this.closeSnackbar();
        },
    },

    computed: {
        ...mapState({
            automationAbortActive: (state) => state.automationAbortActive,
            automationAbortData: (state) => state.automationAbortData,
            automationAbortTimeout: (state) => state.automationAbortTimeout,
        }),
        automationAbortActive: {
            get() {
                return this.$store.state.automationAbortActive;
            },
            set(value) {
                this.$store.commit('setAutomationAbortActive', value);
            },
        },
    },
    watch: {
        automationAbortActive(value) {
            if (value) {
                this.interval = setInterval(() => {
                    this.value = this.value - 1;

                    if (this.value < 1) {
                        this.closeSnackbar();
                    }
                }, 100);
            } else {
                clearInterval(this.interval);
                this.value = 100;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.alertBody {
    border-radius: 12px;
    border: 1px solid var(--gray-200, #eaecf0);
    background: var(--base-white, #fff);
    /* Shadow/lg */
    overflow: hidden;
    box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);
    display: flex;
    flex-direction: column;
    align-self: stretch;
    //for absolute alignment of close button
    position: relative;
}

.alertMain {
    padding: 16px 16px 0;
}

.iconOuter {
    position: relative;
    top: -10px;
    left: -10px;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    /* border: 2px solid #e7f5ee; */
}

.iconInner {
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 2px solid #b5dfcc;
}

.alertText {
    color: var(--gray-900, #101828);
    position: relative;
    top: -3px;
    width: 100%;
    font-size: 14px;

    font-style: normal;
    font-weight: 600;
    line-height: 20px;

    position: relative;
    z-index: 1;
    /* Lower z-index than close button */
    //margin right to not overlap with close button
    margin-right: 20px;
}

.alertDescription {
    color: var(--gray-600, #475467);
    position: relative;
    /* top: -10px; */

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 142.857% */
}

.descriptionWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.closeButton {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    margin: 10px;
}
</style>
