<template>
    <div class="empty-state-art fadeIn" style="position: relative; overflow: hidden">
        <div class="background" style="position: absolute; top: 0; right: 0; bottom: 0; left: 0">
            <slot name="background"></slot>
        </div>

        <div class="fade" style="position: absolute; top: 0; right: 0; bottom: 0; left: 0"></div>

        <div
            style="
                position: relative;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 1;
            "
            :style="{ padding: `${this.paddingHeight} 20px` }"
        >
            <EmptyStateCard
                class="dialog fadeIn"
                style="
                    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
                    margin-top: 0;
                "
                v-bind="{
                    ...emptyPropsDefault,
                    ...emptyProps,
                }"
            >
                <slot></slot>

                <template #buttons>
                    <slot name="buttons" />
                </template>
            </EmptyStateCard>
        </div>
    </div>
</template>

<script>
import EmptyStateCard from '@/components/EmptyStateCard.vue';

export default {
    components: {
        EmptyStateCard,
    },
    props: {
        // same props object as the EmptyStateCard
        emptyProps: {
            type: Object,
        },
        paddingHeight: {
            type: String,
            default: '200px',
        },
    },
    data() {
        return {
            emptyPropsDefault: {
                backgroundColor: 'white',
                border: true,
                maxWidth: '445px',
                titleBalancedWrap: true,
            },
        };
    },
};
</script>

<style scoped lang="scss">
.empty-state-art {
}

.dialog {
    animation-delay: 0.05s;
}

.fade {
    background: linear-gradient(180deg, transparent 50%, rgba(#f9fafb, 1) 100%);
}
</style>
